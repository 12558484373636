<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Storage Location Importer</b-card-title>
			<b-card-sub-title>Imports storage location data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Location Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Storage Location Form"
										description="Please select a valid json or csv file for storage location import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<label>
										Download JSON template
										<a :href="jsonTemplateUrl" download="StorageLocationImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="StorageLocationImporter.csv">
											here.
										</a>
									</label>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the storage location import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
	name: 'storage-location-importer',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			file: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonData: null,
			jsonTemplateUrl: '../../assets/files/StorageLocationImporter.json',
			csvTemplateUrl: '../../assets/files/StorageLocationImporter.csv',

			allCompaniesObj: {},
			currUserId: this.$store.getters.loggedUser.id,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
		locationNameRegex() {
			return config.locationNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		addressRegex() {
			return config.addressRegex;
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = { ...this.$store.getters.companies };

			} catch(error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});

						this.jsonData = this.formatJsonData(data, extension);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.storageLocations, extension);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid storage location form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid storage location form in JSON or CSV format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params, extension) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);

				if (extension === 'csv') {
					param.geoaddress = {
						latitude: 0,
						longitude: 0,
					};

					if (param.latitude) {
						if (ImportUtil.isValidNumber(param.latitude)) {
							param.geoaddress.latitude = parseFloat(param.latitude);
						} else {
							param.geoaddress.latitude = param.latitude;
						}
						delete param.latitude;
					}
					if (param.longitude) {
						if (ImportUtil.isValidNumber(param.longitude)) {
							param.geoaddress.longitude = parseFloat(param.longitude);
						} else {
							param.geoaddress.longitude = param.longitude;
						}
						delete param.longitude;
					}
				}

				results.push(param);
			});

			return {
				storageLocations: results,
			};
		},

		async onImportData() {
			try {
				let storageLocationsArr = this.jsonData.storageLocations;
				if (storageLocationsArr.length === 0) {
					this.$toaster.warning('At least 1 storage location is required per import.');
					return;
				} else if (storageLocationsArr.length > 5000) {
					this.$toaster.warning('Only maximum of 5,000 storage locations is allowed per import.');
					return;
				} else if (ImportUtil.hasDuplicates(storageLocationsArr, 'name')) {
					let duplicates = ImportUtil.getDuplicates(
						storageLocationsArr,
						'name'
					);
					this.$toaster.warning(
						'Duplicates detected! Please address the following storage location(s): ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (ImportUtil.hasBlankColumnName(storageLocationsArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(storageLocationsArr)) {
					return;
				}

				await this.saveOnDatabase(this.jsonData);
			} catch (_error) {
				this.$toaster.warning('The imported storage location data is invalid and cannot be processed');
			}
		},
		validateDataImport(storageLocations) {
			let isValid = true;
			let errors = [];

			let companies = Object.values({ ...this.allCompaniesObj }).map(company => company.name);

			_.forEach(storageLocations, (item, key) => {
				let locName = item.name;
				let index = key + 1;
				let errPrefix = (!locName || locName.length === 0) ? 'Item ' + index : 'Location ' + locName;

				// Company
				if (!item.company || item.company.length === 0) {
					errors.push(errPrefix + ' has no company. This is required.');
				} else if (!companies.includes(item.company)) {
					errors.push(errPrefix + ' is associated to company, "' + item.company + '", that you don\'t have access to.');
				}

				// Storage Location Name
				if (!item.name || item.name.length === 0) {
					errors.push(errPrefix + ' has no name. This is required.');
				} else if (item.name > 100) {
					errors.push(errPrefix + ' name max character is only up to 100.');
				} else if (!this.locationNameRegex.test(item.name)) {
					errors.push(errPrefix + ' name should only contain letters, numbers and these special characters: &",.!\'-');
				}

				// Description
				if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
					errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,./():;!*[]');
				}

				// Address
				if (!item.address || item.address === null) {
					errors.push(errPrefix + ' has no address. This is required.');
				} else if (!this.addressRegex.test(item.address)) {
					errors.push(errPrefix + ' address should only contain letters, numbers and these special characters: (),.#\'-');
				}

				// Latitude
				let latitude = item.geoaddress.latitude;
				if (!latitude || latitude.length === 0) {
					errors.push(errPrefix + ' has no latitude. This is required.');
				} else if (latitude && !ImportUtil.isValidNumber(latitude)) {
					errors.push(errPrefix + ' latitude, "' + latitude + '", should be a number.');
				} else if (latitude < -90 || latitude > 90) {
					errors.push(errPrefix + ' latitude must not be less than -90 or greater than 90.');
				}

				// Longitude
				let longitude = item.geoaddress.longitude;
				if (!longitude || longitude.length === 0) {
					errors.push(errPrefix + ' has no longitude. This is required.');
				} else if (longitude && !ImportUtil.isValidNumber(longitude)) {
					errors.push(errPrefix + ' longitude, "' + longitude + '", should be a number.');
				} else if (longitude < -180 || longitude > 180) {
					errors.push(errPrefix + ' longitude must not be less than -180 or greater than 180.');
				}

				// Area Radius
				if (!item.areaRadius || item.areaRadius.length === 0) {
					errors.push(errPrefix + ' has no area radius. This is required.');
				} else if (item.areaRadius && !ImportUtil.isValidNumber(item.areaRadius)) {
					errors.push(errPrefix + ' area radius, "' + item.areaRadius + '", should be a number.');
				} else if (item.areaRadius && !ImportUtil.isValidAreaRadius(item.areaRadius)) {
					errors.push(errPrefix + ' area radius, "' + item.areaRadius + '", should be greater than 10 but less than 1000.');
				}
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},
		async saveOnDatabase(json) {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					storageLocations: json.storageLocations,
				};

				let { data } = await dataImporterApi.importStorageLocations(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let storageLocationsObj = data.storageLocations;
				this.$store.dispatch('updateAllStorageLocations', storageLocationsObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
};
</script>
